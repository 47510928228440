<template>

    <div class="app-box-m">
      <div style="background: #313131; padding: 9px 26px 9px 26px">
        <p style="font-weight: 400; font-size: 11px; color: #FFB72C">由于行业特殊性，本APP可能会被某些系统或手机管家认定为病毒，请大家放心下载使用，安装本APP不会对您的设备造成任何损害。</p>
      </div>
      <div class="p-title-img" loading="lazy"></div>
      <img class="p-01-item" loading="lazy" src="../assets/m/2.svg">
      <p class="p-01-item-next3-1">影子 YINGZI VPN</p>
      <div class="p-01-item-next3">
        <div style="font-weight: 500">
          让世界
        </div>
        <div style="font-weight: 300">
          &nbsp;从此如影随行
        </div>
      </div>
      <p class="p-01-item-next4">Seeing The World Has Never Been Easier</p>
      <div class="box-item-n-1">
        <div class="box-item-n-5">
          <p><span class="box-item-n-3">永久</span></p>
          <p class="box-item-n-2">免费注册</p>
        </div>
        <div class="box-item-n-5 box-item-n-6 ">
          <p><span class="box-item-n-3">无痕</span></p>
          <p class="box-item-n-2">有影无踪</p>
        </div>
        <div class="box-item-n-5">
          <p><span class="box-item-n-3">极速</span></p>
          <p class="box-item-n-2">优享专连</p>
        </div>
      </div>
      <div class="p-04">
        <a    :href="apkurl" target="_blank" class="p-04-item">
          <img class="p-img-10-3" src="../assets/m/android.svg" loading="lazy"/>
          <div>
            <div style="font-size:9px;color: #3470AE ;text-align: left; font-weight: 500">推荐直接下载APK</div>
            <br>
            <div style="font-size:16px;color: #3470AE; text-align: left; margin-top: -20px">Android下载</div>
          </div>
        </a>
        <!-- <img class="p-04-item" loading="lazy" src="../assets/m/25.jpg"> -->
      </div>
<!--      <div class="p-04-0">-->
<!--        <a href="javascript:void(0)"-->
<!--           onclick="location.href='https://play.google.com/store/apps/details?id=com.lvcha.main'" class="p-04-item">-->
<!--          <img class="p-img-10-3" src="../assets/m/google_play.svg" loading="lazy"/>-->
<!--          <div>-->
<!--            <div style="font-size:9px;color: white ;text-align: left; font-weight: 500">商店下载</div>-->
<!--            <br>-->
<!--            <div style="font-size:16px;color: white; text-align: left; margin-top: -18px">Google Play</div>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
<!--      <div class="p-04-0">-->
<!--        <a href="javascript:void(0)"-->
<!--           onclick="location.href='https://apps.apple.com/app/id6736526504'" class="p-04-item">-->
<!--          <img class="p-img-10-3" src="../assets/m/appstore.svg" loading="lazy"/>-->
<!--          <div>-->
<!--            <div style="font-size:9px;color: white ;text-align: left; font-weight: 500">商店下载</div>-->
<!--            <br>-->
<!--            <div style="font-size:16px;color: white; text-align: left; margin-top: -18px">App Store &nbsp;&nbsp;&nbsp;</div>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
      <!-- <div class="p-04">
          <img class="p-04-item" loading="lazy" src="../assets/m/26.jpg">
          <img class="p-04-item" loading="lazy" src="../assets/m/24.jpg">

      </div> -->
      <div>
        <picture>
<!--          <source type="image/webp"  srcset="../assets/m/top1.webp">-->
          <img class="p-06-item" src="../assets/m/top1.png" loading="lazy">
        </picture>
      </div>
      <div class="p-center-1">
        <p class="p-rm-1">推广伙伴计划</p>
        <p class="p-rm-2">加入我们给你的是，高回报&直接收益</p>
      </div>

      <div class="p-04-1">
        <img src="../assets/m/promotion_left1.svg" loading="lazy"/>
        <p class="p-04-item-1">达成条件</p>
      </div>
      <p class="p-04-2">当您的好友通过您的邀请码下载安装包，并打开完成注册，即邀请成功可直接获取免费使用时长；新伙伴可获取免费套餐包；</p>

      <div class="p-04-1">
        <img src="../assets/m/promotion_left2.svg" loading="lazy"/>
        <p class="p-04-item-1">高回报&amp;直接收益</p>
      </div>
      <p class="p-04-2">拉新人活动，注册活动，单项活动赠送时长最高至10天，自动兑换，动手即获免费权益，渠道可直接联系 <a href="https://t.me/yingzivpn">无影少年</a>，奖金丰厚；</p>

      <div class="p-04-1">
        <img src="../assets/m/promotion_left4.svg" loading="lazy"/>
        <p class="p-04-item-1">成为我们的超级推广大使</p>
      </div>
      <p class="p-04-2">
        只要你有品牌宣传力，你就是我们首席运营伙伴，应用自己的小圈子，充实自己的钱袋子。获取更多真金收益，让财富圈起来。</p>

      <div class="p-04-3">
        <a class="p-04-item-2" href="https://t.me/yingzivpn" >立即加入
          与我们合作</a>
      </div>

      <div class="p-center">
        <p class="p-rm">全球热门应用</p>
        <p class="p-rm-3">好玩的好看的全都有</p>
        <picture>
<!--          <source type="image/webp"  srcset="../assets/m/des1.webp">-->
          <img class="p-06-item-1" src="../assets/m/des1.png" loading="lazy">
        </picture>
      </div>
      <!-- 层级 -->
      <div class="box-1">
        <div class="box-2">
          <div class="p-04-4">
            <img class="p-img-10-1" src="../assets/m/hot_icon.svg" loading="lazy"/>
            <p class="p-04-item-3">火爆卖点</p>
          </div>
        </div>
        <div>
          <p class="p-rm-4">首款永久免费无广告的VPN</p>
          <p class="p-rm-3">首款革命性做到真正免费的VPN-影子VPN</p>
          <p class="p-04-5">
            我们推出全新首款永久免费无广告的VPN，对翻墙需求不频繁的用户也能随时联网，彻底告别资金浪费。同时对高需求的用户我们也提供了其它高性价比套餐，让每一位用户都有自己的选择权。</p>
        </div>
        <picture>
<!--          <source type="image/webp"  srcset="../assets/m/hot1.webp">-->
          <img class="p-06-item-2" src="../assets/m/hot1.png" loading="lazy">
        </picture>
      </div>
      <div class="box-1">
        <div class="box-2">
          <div class="p-04-4">
            <img class="p-img-10-1" src="../assets/m/hot_icon.svg" loading="lazy"/>
            <p class="p-04-item-3">火爆卖点</p>
          </div>
        </div>
        <div>
          <p class="p-rm-4">解决痛点 打破报毒封锁</p>
          <p class="p-rm-3">热门App一键内置/轻松安装</p>
          <p class="p-04-5">
            独创内置私密浏览器，全渠道加密体验，守护用户隐私，无需实名。零解析，真正100%隐藏自己，无痕浏览，突破网站屏蔽，开启极限网络。</p>
        </div>
        <picture>
<!--          <source type="image/webp"  srcset="../assets/m/hot2.webp">-->
          <img class="p-06-item-2" src="../assets/m/hot2.png" loading="lazy">
        </picture>
      </div>

      <div class="box-1">
        <div class="box-2">
          <div class="p-04-4">
            <img class="p-img-10-1" src="../assets/m/hot_icon.svg" loading="lazy"/>
            <p class="p-04-item-3">火爆卖点</p>
          </div>
        </div>
        <div>
          <p class="p-rm-4">海量免费视频频道</p>
          <p class="p-rm-3">更有贴心的实时新闻给您第一手资讯</p>
          <p class="p-04-5">
            精准定位用户需求，一站式畅游各色平台。智能识别地域与语言，实时捕捉全球资讯，多视角、多平台，轻松纵览天下事。</p>
        </div>
        <picture>
<!--          <source type="image/webp"  srcset="../assets/m/hot3.webp">-->
          <img class="p-06-item-2" src="../assets/m/hot3.png" loading="lazy">
        </picture>
      </div>

      <div class="box-1">
        <div class="box-2">
          <div class="p-04-4">
            <img class="p-img-10-1" src="../assets/m/hot_icon.svg" loading="lazy"/>
            <p class="p-04-item-3">火爆卖点</p>
          </div>
        </div>
        <div>
          <p class="p-rm-4">Chatgpt 站内一键导航</p>
          <p class="p-rm-3">最强大语言模型智能机器人</p>
          <p class="p-04-5">
            站内一键启动，让您体验更畅快轻松解决您的问题，让思路不在迷离，让决策不在迷路，尽情享受科技带来的无限可能。</p>
        </div>
        <picture>
<!--          <source type="image/webp"  srcset="../assets/m/hot4.webp">-->
          <img class="p-06-item-2" src="../assets/m/hot4.png" loading="lazy">
        </picture>
      </div>

      <div class="box-1">
        <div>
          <p class="p-g-1">用户评价</p>
        </div>
        <div><p class="p-04-6">
          终于找到了，永久免费竟然还没有流量限制，每天都离不开，google和YouTube，最最最大的福利是tumblr看下视频一点都不卡，老司机懂的！</p>
        </div>
        <div class="p-04-7">
          <div class="p-04-8">
            <picture>
              <source type="image/webp"  srcset="../assets/m/person1.webp">
              <img class="p-06-item-3" src="../assets/m/person1.png" loading="lazy">
            </picture>
          </div>
          <div class="p-04-8-1">
            <picture>
              <source type="image/webp"  srcset="../assets/m/person2.webp">
              <img class="p-06-item-4" src="../assets/m/person2.png" loading="lazy">
            </picture>
          </div>
          <div class="p-04-8">
            <picture>
              <source type="image/webp"  srcset="../assets/m/person3.webp">
              <img class="p-06-item-3" src="../assets/m/person2.png" loading="lazy">
            </picture>
          </div>
        </div>
        <p class="p-04-9">Alexa Vega</p>
        <p class="p-04-10">Google play</p>
        <div>
          <p class="p-04-11">影子套餐价格</p>
          <p class="p-04-12">只为追求更好的品质</p>
        </div>
        <div style="display: flex; margin-top: 28px;">
          <img class="p-04-13-1" src="../assets/m/zuanshi_vip.png" loading="lazy">
          <p class="p-04-13">超值套餐</p>
        </div>

        <div class="p-04-14">
          <div class="p-04-15">
            <div class="p-04-16">
              <p class="p-04-17">小白入门</p>
            </div>
            <div>
              <div class="p-04-18-2">
                <p class="p-04-18-1">￥</p>
                <p class="p-04-18">10</p>
              </div>
              <p class="p-04-19">3设备3节点</p>
              <div class="p-04-20">
                <p class="p-04-21">15天</p>
              </div>
            </div>
          </div>
          <div class="p-04-15">
            <div class="p-04-16">
              <p class="p-04-17">月享套餐</p>
            </div>
            <div>
              <div class="p-04-18-2">
                <p class="p-04-18-1">￥</p>
                <p class="p-04-18">15</p>
              </div>
              <p class="p-04-19">3设备10节点</p>
            </div>
            <div class="p-04-20">
              <p class="p-04-21">30天</p>
            </div>
          </div>
          <div class="p-04-15">
            <div class="p-04-16">
              <p class="p-04-17">季享套餐</p>
            </div>
            <div>
              <div class="p-04-18-2">
                <p class="p-04-18-1">￥</p>
                <p class="p-04-18">30</p>
              </div>
              <p class="p-04-19">5设备15节点</p>
            </div>
            <div class="p-04-20">
              <p class="p-04-21">90天</p>
            </div>
          </div>
          <div class="p-04-15">
            <div class="p-04-16">
              <p class="p-04-17">年享套餐</p>
            </div>
            <div>
              <div class="p-04-18-2">
                <p class="p-04-18-1">￥</p>
                <p class="p-04-18">100</p>
              </div>
              <p class="p-04-19">5设备15节点</p>
            </div>
            <div class="p-04-20">
              <p class="p-04-21">360天</p>
            </div>
          </div>
        </div>
        <div style="display: flex; margin-top: 18px;">
          <img class="p-04-13-1" src="../assets/m/huangjin_vip.png" loading="lazy">
          <p class="p-04-13">优享活动</p>
        </div>
        <div class="p-04-14">
          <div class="p-04-15-1">
            <div class="p-04-16-1">
              <p class="p-04-17">拉新活动</p>
            </div>
            <div>
              <div class="p-04-18-2">
                <p class="p-04-18-1">新增</p>
                <p class="p-04-18">1名</p>
              </div>
              <p class="p-04-19">新成员获免费套餐，邀请人获赠10天时长</p>
            </div>
          </div>
          <div class="p-04-15-1">
            <div class="p-04-16-1">
              <p class="p-04-17">保留火种</p>
            </div>
            <div>
              <div class="p-04-18-2">
                <p class="p-04-18-1">HUB</p>
                <p class="p-04-18" style="font-size: 20px">迁移</p>
              </div>
              <p class="p-04-19">免费兑积分，激活送专享</p>
            </div>
            <div class="p-04-20-1">
              <p class="p-04-21">限时</p>
            </div>
          </div>
          <div class="p-04-15-1">
            <div class="p-04-16-1">
              <p class="p-04-17">避免失联</p>
            </div>
            <div>
              <div class="p-04-18-2">
                <p class="p-04-18-1">🚀</p>
                <p class="p-04-18">群聊</p>
              </div>
              <p class="p-04-19">赠10天时长</p>
            </div>
            <div class="p-04-20-1">
              <p class="p-04-21">限时</p>
            </div>
          </div>
          <div class="p-04-15-1">
            <div class="p-04-16-1">
              <p class="p-04-17">乐享积分</p>
            </div>
            <div>
              <div class="p-04-18-2">
                <p class="p-04-18-1">💰</p>
                <p class="p-04-18">400</p>
              </div>
              <p class="p-04-19">20积分起兑</p>
            </div>
            <div class="p-04-20-1">
              <p class="p-04-21">超低门槛</p>
            </div>
          </div>
        </div>

        <div class="p-04-22">
          <p class="p-04-23">权益\类型</p>
          <p class="p-04-23">超值套餐</p>
          <p class="p-04-23">专享套餐</p>
          <p class="p-04-23">免费套餐</p>
        </div>
        <div class="p-04-24">
          <p class="p-04-25">网络限速</p>
          <p class="p-04-25">不限速</p>
          <p class="p-04-25">不限速</p>
          <p class="p-04-25">不限速</p>
        </div>
        <div class="p-04-26">
          <p class="p-04-25">流量上限</p>
          <p class="p-04-25">无上限</p>
          <p class="p-04-25">无上限</p>
          <p class="p-04-25">无上限</p>
        </div>
        <div class="p-04-24">
          <p class="p-04-25">专属线路</p>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
          <div>
            <img class="p-04-27" src="../assets/m/not_selected.svg" loading="lazy"/>
          </div>
          <p class="p-04-25">免费线路</p>
        </div>
        <div class="p-04-26">
          <p class="p-04-25">极速模式</p>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
        </div>
        <div class="p-04-24">
          <p class="p-04-25">游戏模式</p>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
          <div>
            <img class="p-04-27" src="../assets/m/not_selected.svg" loading="lazy"/>
          </div>
        </div>
        <div class="p-04-26">
          <p class="p-04-25">安全模式</p>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
          <div>
            <img class="p-04-27" src="../assets/m/not_selected.svg" loading="lazy"/>
          </div>
        </div>
        <div class="p-04-24">
          <p class="p-04-25">APP加速</p>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
          <div>
            <img class="p-04-27" src="../assets/m/not_selected.svg" loading="lazy"/>
          </div>
          <div>
            <img class="p-04-27" src="../assets/m/not_selected.svg" loading="lazy"/>
          </div>
        </div>
        <div class="p-04-26">
          <p class="p-04-25">节点选择</p>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
          <div>
            <img class="p-04-27" src="../assets/m/not_selected.svg" loading="lazy"/>
          </div>
        </div>
        <div class="p-04-24">
          <p class="p-04-25">应用大全</p>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
        </div>
        <div class="p-04-26">
          <p class="p-04-25">隐私保护</p>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
          <div>
            <img class="p-04-27" src="../assets/m/selected.svg" loading="lazy"/>
          </div>
        </div>

        <div class="p-04-29">
          <a    :href="apkurl" target="_blank" class="p-04-item-4">
            立即免费试用
          </a>
          <!-- <img class="p-04-item" loading="lazy" src="../assets/m/25.jpg"> -->
        </div>
        <!-- <div style="margin-bottom: 30px; margin-top: 10px;">
            <img class="p-img-16" loading="lazy" src="../assets/m/30.jpg">
            <img class="p-img-16" loading="lazy" src="../assets/m/31.jpg">
        </div> -->
      </div>

      <div class="box-li-2">
<!--        <p>-->
<!--          <span class="p-circle"></span>-->
<!--          <span class="p-m-item">在社交媒体上随时找到我们</span>-->
<!--        </p>-->
<!--        <p class="box-item-img-text">-->
<!--          <img class="p-item-img" loading="lazy" src="../assets/m/twitter.svg">-->
<!--          <a class="item-li" href="https://x.com/lvchavpn">x.com/lvchavpn</a>-->
<!--        </p>-->
<!--        <p class="box-item-img-text">-->
<!--          <img class="p-item-img" loading="lazy" src="../assets/m/18.png">-->
<!--          <a class="item-li" href="https://www.instagram.com/lvchavpn2024">instagram.com/lvchavpn2024</a>-->
<!--        </p>-->
<!--        <p class="box-item-img-text">-->
<!--          <img class="p-item-img" loading="lazy" src="../assets/m/facebook.svg">-->
<!--          <a class="item-li" href="https://www.facebook.com/profile.php?id=61557724277526&mibextid=AEUHqQ">facebook.com/LV-Cha</a>-->
<!--        </p>-->

        <p style="margin-top: 14px">
          <span class="p-circle"></span>
          <span class="p-m-item">通过邮件联系在线客服（分钟级别回复）</span>
        </p>

        <p class="box-item-img-text">
          <img class="p-item-img" loading="lazy" src="../assets/m/mail.svg">
          <a class="item-li" href="Mailto:bobnice1899@gmail.com">bobnice1899@gmail.com</a>
        </p>

        <p style="margin-top: 14px">
          <span class="p-circle"></span>
          <span class="p-m-item">加入官方Telegram群组</span>
        </p>
        <p class="box-item-img-text">
          <img class="p-item-img" loading="lazy" src="../assets/m/21.png">
          <a class="item-li" href="https://t.me/+egDKJw_6oOQzMGJl">影子官方群</a>
        </p>
      </div>
      <span class="line"></span>

      <div class="p-bottom-text-item">
        <p class="p-bottom-text p-lg">
          <span class="l-g">了解更多</span>
          <span class="l-g">关于影子</span>
        </p>
        <p class="p-bottom-text">
          <span class="item-p">PC版本(即将上线)</span>
          <a href="assets/privacy.html"><span class="item-p">隐私政策</span></a>
        </p>
        <p class="p-bottom-text">
          <a  > <span class="item-p">Android版本</span></a>
          <a href="assets/terms.html"><span class="item-p">服务条款</span></a>
        </p>
        <p class="p-bottom-text">
          <span class="item-p">iOS版本(即将上线)</span>
        </p>
        <p class="p-bottom-text">
          <span class="item-p">Mac版本(即将上线)</span>
        </p>
      </div>
      <span class="line"></span>
      <p class="p-btn-p"><img class="p-btn-img" loading="lazy" src="../assets/m/23.png"> <span
          class="pbtn-text">简体中文</span></p>
    </div>
</template>

<style>
    *{
        font-family: math;
    }
    .van-circle__layer {
        stroke: #e2e6eb;
    }
</style>
<script>
import {initApkHub, newestApkUrl, queryApkHub} from "@/api/index";
import QRCode from 'qrcode'
import Vue from 'vue';
import { Cell, CellGroup } from 'vant';
import { Circle } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Toast } from 'vant';
import { Image as VanImage } from 'vant';
import { Card } from 'vant';
import { Loading } from 'vant';
import { Overlay } from 'vant';
import { Notify } from 'vant';

Vue.use(Notify);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Card);
Vue.use(VanImage);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Circle);
Vue.use(Cell);
Vue.use(CellGroup);
import  apkhubLogo  from "../../assets/img/APKHub.png";
export default {
  data () {
    return {
      sbtShow:false,
      qrCodeDataUrl: '', // 生成的二维码 URL
      showqQr : false,
      showDone:false,
      apkhubLogo:apkhubLogo,
      currentRate: 0,
      currentRate1: 10,
      currentRate2: 20,
      currentRate3: 30,
      currentRate4: 40,
      username: '',
      password: '',
      newOrderId:null,
      timer: null,       // 保存定时器的 ID
      apkurl:'',
    }
  },
  components: {
  },
  watch: {
    // 监听 newOrderId 变量的变化
    newOrderId(newValue) {
      if (newValue) {
       // console.log('newOrderId 变化，启动定时任务');
        this.startTimer();  // 启动定时任务
      } else {
        this.stopTimer();   // 如果 newOrderId 为空，停止定时任务
      }
    }
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + '%';
    },
  },
  mounted() {
    document.documentElement.style.fontSize = '10vw';
    document.title = '移动首页';  // 修改页面标题
    // 判断是否是移动端
    const isMobile = this.isMobileDevice();
    const isMobileEx = this.isMobileBrowerSize();
    // 如果是移动端，则跳转到 "/phome" 页面
    if (isMobile||isMobileEx) {
      this.$router.replace('/mhome');
    }else{
      this.$router.replace('/phome');
    }
  },
  methods: {
    // 判断设备是否为移动端
    isMobileDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      // 使用正则检查 userAgent 中是否包含手机/平板的关键字
      return /iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile|webos.*mobile/i.test(userAgent);
    },
    isMobileBrowerSize() {
      return window.innerWidth <= 768;  // 视窗宽度小于等于 768px 视为移动端
    },
    async getDownloadUrl() {
      var resp = await newestApkUrl();
      this.apkurl = resp.data.updateUrl;
    },
  },
  beforeDestroy() {

  },
  created() {
    this.getDownloadUrl();
  }
}
</script>

<style lang="less" scoped>

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background: linear-gradient(to bottom, #f7fff9, #FFF); /* 上下渐变色 */
  width: 100%;
  overflow-x: hidden;
}

p {
  margin: 0;
  padding: 0;
}

/* 移动端样式 */

.p-01-item {
  margin: 23px 0 0 41px;
  width: 75px;
  height: 42px;
  object-fit: cover;
}

.p-01-item-next {
  margin-left: 44px;
  margin-top: 58px;
  background-color: white;
  height: 2px;
  width: 14px;
  /* length具体尺寸*/
  border-radius: 1px;
}

.p-01-item-next2 {
  margin-left: 44px;
  margin-top: 7px;
  background-color: white;
  height: 2px;
  width: 14px;
  /* length具体尺寸*/
  border-radius: 1px;
}

.p-01-item-next3-1 {
  position: relative;
  border-radius: 18px;
  margin-top: 41px;
  width: 272px;
  margin-left: 50%;
  left: -136px;
  border: 2px solid white;
  height: 35px;
  text-align: center;
  line-height: 35px;
  font-size: 18px;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: white;
}

.p-01-item-next3 {
  display: flex;
  margin-top: -6px;
  font-size: 30px;
  line-height: 54px;
  justify-content: center;
  font-family: SourceHanSansCN;
  color: white;
  text-align: center;
}

.p-01-item-next4 {
  font-size: 12px;
  font-family: SourceHanSansCN;
  font-weight: 400;
  margin-top: -10px;
  color: #FFFFFF;
  text-align: center;
}

.p-04-item {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  text-decoration: none;
  align-items: center;
}

.p-04-item-1 {
  margin-left: 7px;
  line-height: 35px;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  color: #313131;
}

.p-04-item-2 {
  position: relative;
  width: 100%;
  height: 52px;
  line-height: 52px;
  font-size: 15px;
  color: white;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
}

.p-04-item-3 {
  height: 24px;
  line-height: 24px;
  margin-left: 3px;
  font-size: 10px;
  text-align: center;
  color: white;
  text-decoration: none;
}

.p-04-item-4 {
  position: relative;
  width: 100%;
  height: 52px;
  line-height: 52px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #FF546A;
  text-decoration: none;
}

.p-img-10-3 {
  height: 30px;
  margin-right: 10px;
  position: relative;
  vertical-align: middle;
}

.p-img-10-1 {
  width: 12px;
  height: 100%;
  vertical-align: middle;
}

.p-06-item {
  width: 100%;
  height: 100%;
  margin-top: 8px;
  justify-content: center;
  object-fit: contain;
}

.p-06-item-1 {
  width: 100%;
  height: 100%;
  padding-top: -47px;
  justify-content: center;
  object-fit: contain;
}

.p-06-item-2 {
  width: 202px;
  height: 246px;
  justify-content: center;
  object-fit: contain;
}

.p-06-item-3 {
  width: 100%;
  height: 100%;
  justify-content: center;
  object-fit: contain;
  vertical-align: middle;
}

.p-06-item-4 {
  width: 100%;
  justify-content: center;
  object-fit: contain;
  height: 100%;
  vertical-align: middle;
}

.p-04 {
  display: flex;
  height: 50px;
  margin-left: 35px;
  margin-right: 35px;
  text-align: center;
  margin-top: 25px;
  border-radius: 29px;
  background-color: white;
  justify-content: center;
}

.p-04-0 {
  display: flex;
  height: 50px;
  margin-left: 35px;
  margin-right: 35px;
  text-align: center;
  margin-top: 10px;
  border-radius: 29px;
  border: 1px solid white;
  justify-content: center;
}

.p-04-1 {
  display: flex;
  margin-left: 35px;
  text-align: left;
  margin-top: 21px;
  justify-content: left;
}

.p-04-2 {
  margin-left: 35px;
  margin-right: 35px;
  text-align: left;
  margin-top: 3px;
  justify-content: left;
  font-family: SourceHanSansCN;
  font-size: 12px;
  color: #6C6C6C;
}

.p-04-3 {
  display: flex;
  margin-left: 35px;
  margin-right: 35px;
  text-align: center;
  margin-top: 51px;
  border-radius: 26px;
  background-color: #3470AE;
  justify-content: center;
}

.p-04-4 {
  display: flex;
  width: 80px;
  margin-top: 82px;
  height: 24px;
  text-align: center;
  border-radius: 12px;
  background-color: #3470AE;
  justify-content: center;
}

.p-04-5 {
  margin-left: 40px;
  margin-right: 45px;
  text-align: left;
  margin-top: 24px;
  justify-content: left;
  font-family: SourceHanSansCN;
  font-size: 13px;
  color: #6F7074;
  line-height: 18px;
}

.p-04-6 {
  margin-left: 35px;
  margin-right: 35px;
  text-align: left;
  margin-top: 8px;
  justify-content: left;
  font-family: SourceHanSansCN;
  font-size: 13px;
  color: #6F7074;
  line-height: 18px;
}

.p-04-7 {
  display: flex;
  text-align: center;
  margin-top: 37px;
  justify-content: center;
  align-items: center;
}

.p-04-8 {
  width: 41px;
  height: 41px;
  text-align: center;
  justify-content: center;
}

.p-04-8-1 {
  width: 62px;
  height: 62px;
  margin-left: 34px;
  margin-right: 34px;
  text-align: center;
  justify-content: center;
}

.p-04-9 {
  text-align: center;
  margin-top: 18px;
  justify-content: center;
  font-family: SourceHanSansCN;
  font-size: 16px;
  color: #313131;
  line-height: 18px;
}

.p-04-10 {
  text-align: center;
  justify-content: center;
  font-family: SourceHanSansCN;
  font-size: 10px;
  font-weight: 400;
  color: #7C7C7C;
}

.p-04-11 {
  text-align: center;
  justify-content: center;
  margin-top: 87px;
  font-family: SourceHanSansCN;
  font-size: 36px;
  font-weight: 500;
  color: #313131;
}

.p-04-12 {
  text-align: center;
  justify-content: center;
  font-family: SourceHanSansCN;
  font-size: 13px;
  font-weight: 500;
  color: #313131;
}

.p-04-13 {
  text-align: left;
  justify-content: left;
  font-family: SourceHanSansCN;
  font-size: 16px;
  font-weight: 500;
  color: #313131;
}

.p-04-13-1 {
  width: 24px;
  margin-left: 26px;
  margin-right: 3px;
  justify-content: center;
  object-fit: contain;
}

.p-04-14 {
  display: grid;
  grid-template-columns:repeat(4, 1fr);
  grid-gap: 10px;
  height: 103px;
  margin-top: 5px;
  margin-left: 26px;
  margin-right: 26px;
}

.p-04-15 {
  position: relative;
  border-radius: 9px 28px 9px 9px;
  border: 2px solid #521EE5;
  height: 100%;
}

.p-04-15-1 {
  position: relative;
  border-radius: 9px 28px 9px 9px;
  border: 2px solid #EA6E11;
  height: 100%;
}

.p-04-16 {
  text-align: left;
  width: 100%;
}

.p-04-16-1 {
  margin-top: -2px;
  text-align: left;
  width: 100%;
}

.p-04-17 {
  font-family: SourceHanSansCN;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  margin-top: 17px;
  margin-left: 10px;
  font-weight: 500;
  color: #313131;
}

.p-04-18 {
  font-family: SourceHanSansCN;
  font-size: 23px;
  font-weight: 500;
  color: #313131;
}

.p-04-18-1 {
  font-family: SourceHanSansCN;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #313131;
}

.p-04-18-2 {
  display: flex;
  align-items: flex-end;
  margin-left: 8px;
  justify-content: left;
}

.p-04-19 {
  font-family: SourceHanSansCN;
  font-size: 11px;
  margin-top: -3px;
  font-weight: 400;
  margin-left: 8px;
  text-align: left;
  color: #585858;
}

.p-04-20 {
  position: absolute;
  border-radius: 0 7px 0 7px;
  background: #521EE5;
  text-align: center;
  width: 32px;
  height: 15px;
  bottom: 0;
}

.p-04-20-1 {
  position: absolute;
  border-radius: 0 7px 0 7px;
  background: #EA6E11;
  text-align: center;
  width: 32px;
  height: 15px;
  bottom: 0;
}

.p-04-21 {
  font-family: SourceHanSansCN;
  line-height: 15px;
  font-size: 8px;
  font-weight: 400;
  color: white;
}

.p-04-22 {
  display: grid;
  grid-template-columns:repeat(4, 1fr);
  grid-gap: 0;
  border-radius: 10px 10px 0 0;
  background: linear-gradient(to left, #FFF6E2, #FFD0AE);
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 23px;
  height: 39px;
}

.p-04-23 {
  font-family: SourceHanSansCN;
  height: 39px;
  line-height: 39px;
  font-size: 15px;
  font-weight: 500;
  color: #313131;
}

.p-04-24 {
  display: grid;
  grid-template-columns:repeat(4, 1fr);
  grid-gap: 0;
  background-color: #F5F5F5;
  margin-left: 25px;
  margin-right: 25px;
  height: 35px;
}

.p-04-25 {
  font-family: SourceHanSansCN;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  font-weight: 400;
  color: #313131;
}

.p-04-26 {
  display: grid;
  grid-template-columns:repeat(4, 1fr);
  grid-gap: 0;
  background-color: white;
  margin-left: 25px;
  margin-right: 25px;
  height: 35px;
}

.p-04-27 {
  text-align: center;
  height: 100%;
  width: 12px;
}

.p-04-28 {
  font-family: SourceHanSansCN;
  font-size: 16px;
  margin-top: 67px;
  font-weight: 500;
  color: #313131;
}

.p-04-29 {
  display: flex;
  height: 52px;
  width: 50%;
  margin-left: 25%;
  text-align: center;
  margin-top: 72px;
  margin-bottom: 59px;
  border: 2px solid #FF546A;
  border-radius: 34px;
  justify-content: center;
}


.p-center {
  margin-top: 44px;
}

.p-center-1 {
  margin-top: 42px;
}

footer {
  background-color: #3498db;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.p-circle {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px;
  border: 1px solid #3470AE;
  background-color: transparent;
  border-radius: 50%;
}

.p-circle::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: 9px;
  height: 9px;
  background-color: #3470AE;
  border-radius: 50%;
}

.box-1 {
  text-align: center;
}

.box-2 {
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
}

.p-g-1 {
  font-size: 23px;
  font-family: SourceHanSansCN;
  color: #313131;
  margin-top: 94px;
  font-weight: 500;
}

.p-m-item {
  font-size: 12px;
  font-family: SourceHanSansCN;
  font-weight: bold;
  color: #313131;
}

.p-item-img {
  width: 32px;
  height: 33px;
  vertical-align: middle;
  margin-right: 16px;
  object-fit: cover;
}

.box-item-img-text {
  width: 250px;
  height: 33px;
  border: 1px solid #ededed;
  border-radius: 12px;
}

.item-li {
  font-size: 12px;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: #313131;
  vertical-align: text-bottom;
}

.box-li-2 {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 45px;
}

.box-li-2 p {
  margin-bottom: 10px;
}

.p-bottom-text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
}

.box-item-n-1 {
  display: flex;
  justify-content: center;
  margin-top: 21px;
}

.box-item-n-2 {
  font-size: 14px;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: #FFFFFF;
}

.box-item-n-3 {
  font-size: 26px;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: #FFFFFF;
}

.box-item-n-4 {
  font-size: 13px;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: #FFFFFF;
}

.box-item-n-5 {
  width: 25%;
  text-align: center;
}

.box-item-n-6 {
  border-right: 0.5px solid #fff;
  border-left: 0.5px solid #fff;
}

.p-title-img {
  background: linear-gradient(to bottom, #3470AE, #FFFFFF);
  width: 100%;
  height: 990px;
  position: absolute;
  z-index: -1;
  object-fit: cover;

}

.p-bottom-text-item {
  width: 80%;
  margin: 0 auto;
}

.item-p {
  font-size: 11px;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: #6F7074;
}

.l-g {
  font-size: 16px;
  font-family: SourceHanSansCN;
  font-weight: bold;
  color: #393939;
}

.p-lg {
  margin-bottom: 23px;
  margin-top: 30px;
}

.p-btn-img {
  width: 13px;
  height: 13px;
  object-fit: cover;
  vertical-align: middle;
}

.pbtn-text {
  font-size: 11px;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: #6F7074;
}

.p-btn-p {
  text-align: end;
  padding-right: 40px;
  margin-bottom: 20px;
  margin-top: 11px;
}

.line {
  display: block;
  margin: 0 auto;
  width: 90%;
  height: 1px;
  background: #B1B1B1;
}

.p-rm-1 {
  text-align: center;
  font-size: 36px;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: #313131;
}

.p-rm-2 {
  text-align: center;
  font-size: 13px;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: #313131;
}

.p-rm {
  text-align: center;
  font-size: 36px;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: #313131;
}

.p-rm-3 {
  text-align: center;
  font-size: 13px;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: #313131;
}

.p-rm-4 {
  text-align: center;
  font-size: 23px;
  font-family: SourceHanSansCN;
  font-weight: bold;
  color: #313131;
}

</style>

